<template>
  <div class="d-flex flex-column justify-center">
    <v-expansion-panels
      v-model="panels"
      variant="accordion"
      flat
      tile
    >
      <v-expansion-panel
        v-model="panels"
        expand
        class="secondary"
      >
        <v-expansion-panel-title>
          <div class="d-flex align-center text-overline">
            <v-icon
              start
              :icon="mdiCurrencyUsd"
            />
            Keys with funds ({{ keys.funds.length }})
          </div>
        </v-expansion-panel-title>
        <v-expansion-panel-text v-if="keys.funds.length > 0" class="pt-3">
          <template v-for="(key, index) in keys.funds" :key="index">
            <v-card
              variant="tonal"
              elevation="3"
              class="mb-2"
              link
              @click="setKey(key, key.keyPath)"
            >
              <v-card-text
                id="connectCasperSigner"
                class="d-flex align-start"
              >
                <div>
                  <span class="text-body-1">
                    <v-icon :icon="mdiAccount" />
                    {{ truncate(key.key, {size: 40}) }}
                  </span>
                  <div class="text-left">
                    <v-icon :icon="mdiCurrencyUsd" />
                    {{ key.balance }} CSPR
                  </div>
                </div>
                <v-icon class="ml-auto" :icon="mdiChevronRight" />
              </v-card-text>
            </v-card>
          </template>
        </v-expansion-panel-text>
      </v-expansion-panel>
      <v-expansion-panel
        v-model="panels"
        expand
        class="secondary"
      >
        <v-expansion-panel-title>
          <div class="d-flex align-center text-overline">
            <v-icon
              start
              :icon="mdiCurrencyUsdOff"
            />
            Keys without funds ({{ keys.noFunds.length }})
          </div>
        </v-expansion-panel-title>
        <v-expansion-panel-text class="pt-3">
          <template v-for="(key, index) in keys.noFunds" :key="index">
            <v-card
              variant="tonal"
              elevation="3"
              class="mb-2"
              link
              @click="setKey(key, key.keyPath)"
            >
              <v-card-text
                id="connectCasperSigner"
                class="d-flex align-start"
              >
                <div>
                  <span class="text-body-1">
                    <v-icon :icon="mdiAccount" />
                    {{ truncate(key.key, {size: 40}) }}
                  </span>
                  <div class="text-left">
                    <v-icon :icon="mdiCurrencyUsd" />
                    {{ key.balance }} CSPR
                  </div>
                </div>
                <v-icon class="ml-auto" :icon="mdiChevronRight" />
              </v-card-text>
            </v-card>
          </template>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-btn
      class="mt-2 text-center"
      variant="tonal"
      elevation="3"
      rounded
      :loading="loadingKeys"
      @click="loadMoreKeys"
    >
      Load more keys
    </v-btn>
  </div>
</template>

<script>
import { mdiAccount, mdiChevronRight, mdiCurrencyUsd, mdiCurrencyUsdOff } from '@mdi/js';
import truncate from '../../helpers/truncate.js';

export default {
  props: {
    keys: {
      required: true,
    },
  },
  name: 'AccountList',
  data: () => ({
    mdiCurrencyUsd,
    mdiAccount,
    mdiChevronRight,
    mdiCurrencyUsdOff,
    panels: undefined,
    loadingKeys: false,
  }),
  watch: {
    keys: {
      handler(){
        this.loadingKeys = false;
      },
      deep: true,
    }
  },
  mounted() {
    if (this.keys.funds.length > 0 ){
      this.panels = 0;
    } else {
      this.panels = 1;
    }
  },
  methods: {
    truncate,
    loadMoreKeys() {
      this.$emit('loadMoreKeys');
      this.loadingKeys = true;
    },
    setKey(key, keyPath) {
      this.$emit('setKey', { key, keyPath });
    },
  },
};
</script>
