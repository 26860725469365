// Vuetify
import { createVuetify } from 'vuetify';
import { mdi, aliases } from 'vuetify/iconsets/mdi-svg';
import 'vuetify/styles';

export default createVuetify({
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi,
    },
  },
  theme: {
    defaultTheme: 'dark',
    variations: {
      colors: ['background', 'surface'],
      lighten: 1,
      darken: 2,
    },
    themes: {
      dark: {
        dark: true,
        colors: {
          background: '#0a2539',
          'on-background': '#ffffff',
          surface: '#183045',
          'on-surface': '#ffffff',
        },
      },
      light: {
        dark: false,
        colors: {
          background: '#d5e3ff',
          surface: '#ffffff',
          'on-background': '#000000',
          'on-surface': '#000000',
        },
      },
    },
  },
});
