<template>
  <v-card
    variant="tonal"
    :link="!disabled()"
    class="mb-4"
    style="opacity: 1!important;"
  >
    <v-card-text
      class="d-flex align-center"
    >
      <img
        :src="logo"
        width="32"
        :alt="`${title} Logo`"
        class="mr-3"
      >
      <div>
        <span class="text-body-1">
          {{ title }}
        </span>
        <div>{{ description }}</div>
      </div>
      <v-icon class="ml-auto" v-if="!disabled()">
        {{ mdiChevronRight }}
      </v-icon>
    </v-card-text>
    <div class="pb-3 px-3 d-flex align-center text-small" v-if="disabled()">
      <v-icon start color="red">
        {{ mdiAlertCircle }}
      </v-icon>
      {{ title }} isn't installed.
      <v-spacer />
      <a
        @click.stop
        :href="download"
        target="_blank"
        rel="noopener"
      >
        Download {{ title }}
      </a>
    </div>
    <slot name="additionalDetails"/>
  </v-card>
</template>

<script>
import { mdiAlertCircle, mdiChevronRight } from '@mdi/js';

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    logo: {
      type: String,
      required: true,
    },
    disabled: {
      type: Function,
      required: false,
      default: () => false,
    },
    download: {
      type: String,
      required: false,
      default: '',
    },
  },
  name: 'WalletItem',
  data: () => ({
    mdiAlertCircle,
    mdiChevronRight,
  }),
};
</script>

<style lang="scss" scoped>
  .disabled {
    pointer-events: none;
    user-select: none;
  }
</style>
