import { defineStore } from 'pinia';

export const useWalletStore = defineStore('wallet', {
  state: () => ({
    publicKey: false,
    options: {},
    wallet: '',
  }),
  actions: {
    connect(publicKey, wallet, options = {}) {
      if (publicKey && (wallet === this.wallet || this.wallet === '')) {
        this.publicKey = publicKey;
        this.wallet = wallet;
        this.options = options;
      }
    },
    async disconnect() {
      this.publicKey = false;
      this.wallet = '';
      this.options = {};
      try {
        switch (this.wallet) {
          case 'torus':
            await this.options.torusInstance.logout();
            break;
          case 'casperwallet':
            await window.CasperWalletInstance.disconnectFromSite();
            break;
        }
      } catch (e) {
        console.log(e);
      }
    },
  }
});
