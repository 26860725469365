<template>
  <v-dialog
    v-model="dialog"
    width="600"
    class="pa-0"
  >
    <v-card v-if="!connected">
      <v-card-title>
        <v-avatar>
          <v-icon :icon="mdiWallet" />
        </v-avatar>
        Connect to a wallet
      </v-card-title>
      <v-card-text>
        <v-slide-x-transition
          :group="true"
          leave-absolute
          hide-on-leave
          mode="out-in"
        >
          <WalletList
            v-if="!loading && !ledgerType && !chooseLedgerKey && !chooseMetamaskKey"
            key="walletList"
            @wallet="onWallet"
          />
          <LedgerConnectionTypes
            v-if="ledgerType"
            @usb="connectLedgerWithConnectionType"
          />
          <AccountList
            v-if="chooseLedgerKey"
            :keys="ledgerKeys"
            @loadMoreKeys="loadMoreLedgerKeys"
            @setKey="setKey"
          />
          <AccountList
            v-if="chooseMetamaskKey"
            :keys="metamaskKeys"
            @loadMoreKeys="loadMoreMetamaskKeys"
            @setKey="setKey"
          />
          <v-card
            variant="tonal"
            class="mb-4"
            key="loading"
            v-if="loading"
          >
            <v-card-text>
              <div class="text-body-1 text-center">
                Waiting for connection...
              </div>
              <div class="text-center">
                <v-progress-circular
                  class="mx-auto mt-3"
                  indeterminate
                  color="white"
                />
              </div>
            </v-card-text>
            <v-card-actions>
              <v-btn
                rounded
                @click="loading = false;"
              >
                Cancel
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-slide-x-transition>
      </v-card-text>
    </v-card>
    <ConnectedProfile
      key="connected"
      v-if="connected"
    />
  </v-dialog>
</template>

<script>
import ConnectedProfile from '@/components/connect/ConnectedProfile.vue';
import WalletList from '@/components/connect/WalletList.vue';
import { fetchBalance } from '@/helpers/balance.js';
import getTorusNetwork from '@/helpers/getTorusNetwork.js';
import { getAccount, getSnap, installSnap } from 'casper-manager-helper';
import { useConnectDialogStore } from '@/store/connectDialog.js';
import { useNetworkStore } from '@/store/network.js';
import { useWalletStore } from '@/store/wallet.js';
import TransportWebBLE from '@ledgerhq/hw-transport-web-ble';
import TransportWebUSB from '@ledgerhq/hw-transport-webusb';
import { mdiWallet } from '@mdi/js';
import Torus from '@toruslabs/casper-embed';
import CasperApp from '@zondax/ledger-casper';
import Big from 'big.js';
import { Signer } from 'casper-js-sdk';
import { mapStores } from 'pinia';
import AccountList from './AccountList.vue';
import LedgerConnectionTypes from './LedgerConnectionTypes.vue';

export default {
  name: 'ConnectDialog',
  components: { AccountList, LedgerConnectionTypes, ConnectedProfile, WalletList },
  data: () => ({
    mdiWallet,
    dialog: false,
    loading: false,
    timeout: false,
    connected: false,
    ledgerType: false,
    chooseLedgerKey: false,
    chooseMetamaskKey: false,
    ledgerKeys: {
      funds: [],
      noFunds: [],
    },
    metamaskKeys: {
      funds: [],
      noFunds: [],
    },
  }),
  mounted() {
    this.connected = this.walletStore.publicKey !== false;
    this.dialog = this.connectDialogStore.dialog;
    this.connectDialogStore.$subscribe((mutation, state) => {
      this.dialog = state.dialog;
    });
    this.walletStore.$subscribe((mutation, state) => {
      this.connected = state.publicKey !== false;
    });
  },
  watch: {
    dialog(newDialog) {
      this.connectDialogStore.dialog = newDialog;
    },
  },
  computed: {
    ...mapStores(useConnectDialogStore, useWalletStore, useNetworkStore),
  },
  methods: {
    startLoading() {
      const timeout = setTimeout(() => {
        if (!this.walletStore.publicKey) {
          this.timeout = true;
          this.loading = false;
        }
      }, 60000);
      this.loading = true;
      return timeout;
    },
    onWallet(wallet) {
      this.walletStore.wallet = wallet;
      switch (wallet) {
        case 'metamask':
          this.connectMetamask();
          break;
        case 'casperwallet':
          this.connectCasperWallet();
          break;
        case 'caspersigner':
          this.connectCasperSigner();
          break;
        case 'ledger':
          this.connectLedger();
          break;
        case 'torus':
          this.connectTorus();
          break;
        default:
          console.log('Not supported wallet');
          break;
      }
      const unsubscribe = this.walletStore.$subscribe(async (mutation, state) => {
        if (state.publicKey && state.wallet === wallet) {
          this.dialog = false;
          this.loading = false;
          this.chooseLedgerKey = false;
          this.chooseMetamaskKey = false;
          this.ledgerType = false;
          this.ledgerKeys = {
            funds: [],
            noFunds: [],
          };
          this.metamaskKeys = {
            funds: [],
            noFunds: [],
          };
          this.$router.push('/accounts/' + state.publicKey);
          unsubscribe();
        }
      });
    },
    async connectMetamask() {
      const inactivity = this.startLoading();
      try {
        await installSnap();
        await getSnap();
        const key = (await getAccount()).toHex();
        const balance = this.motesToCspr(await fetchBalance(key));
        if (Big(balance).gt(0)) {
          this.metamaskKeys.funds.push({ key, balance, keyPath: 0 });
        } else {
          this.metamaskKeys.noFunds.push({ key, balance, keyPath: 0 });
        }
        this.loading = false;
        this.chooseMetamaskKey = true;
        clearTimeout(inactivity);
      } catch (e) {
        console.log(e);
        this.timeout = true;
        this.loading = false;
      }
    },
    async loadMoreMetamaskKeys() {
      const nextKeyPath = this.metamaskKeys.funds.length + this.metamaskKeys.noFunds.length;
      for (let i = nextKeyPath; i < nextKeyPath + 4; i++) {
        // eslint-disable-next-line no-await-in-loop
        const key = (await getAccount(i)).toHex();
        // eslint-disable-next-line no-await-in-loop
        const balance = this.motesToCspr(await fetchBalance(key));
        if (Big(balance).gt(0)) {
          this.metamaskKeys.funds.push({ key, balance, keyPath: i });
        } else {
          this.metamaskKeys.noFunds.push({ key, balance, keyPath: i });
        }
      }
    },
    async connectCasperWallet() {
      this.startLoading();
      try {
        const casperWallet = window.CasperWalletInstance;
        const isConnected = await casperWallet.isConnected();
        if (isConnected) {
          try {
            this.walletStore.connect(await casperWallet.getActivePublicKey(), 'casperwallet');
          } catch (e) {
            window.CasperWalletInstance.requestConnection();
          }
        } else {
          window.CasperWalletInstance.requestConnection();
        }
      } catch (e) {
        console.log(e);
        this.timeout = true;
        this.loading = false;
      }
    },
    async connectCasperSigner() {
      this.startLoading();
      if (await Signer.isConnected()) {
        try {
          this.walletStore.connect(await Signer.getActivePublicKey(), 'caspersigner');
        } catch (e) {
          Signer.sendConnectionRequest();
        }
      } else {
        Signer.sendConnectionRequest();
      }
    },
    connectLedger() {
      this.ledgerType = true;
    },
    async connectLedgerWithConnectionType(usb) {
      this.ledgerType = false;
      const inactivity = this.startLoading();
      try {
        let transport;
        if (usb) {
          transport = await TransportWebUSB.create();
        } else {
          transport = await TransportWebBLE.create();
        }
        const app = new CasperApp(transport);
        const key = `02${(await app.getAddressAndPubKey('m/44\'/506\'/0\'/0/0')).publicKey.toString('hex')}`;
        const balance = this.motesToCspr(await fetchBalance(key));
        if (Big(balance).gt(0)) {
          this.ledgerKeys.funds.push({ key, balance, keyPath: 0 });
        } else {
          this.ledgerKeys.noFunds.push({ key, balance, keyPath: 0 });
        }
        this.walletStore.options = { casperApp: app };
        this.loading = false;
        this.chooseLedgerKey = true;
        clearTimeout(inactivity);
      } catch (e) {
        console.log(e);
        clearTimeout(inactivity);
        this.timeout = true;
        this.loading = false;
      }
    },
    async loadMoreLedgerKeys() {
      const nextKeyPath = this.ledgerKeys.funds.length + this.ledgerKeys.noFunds.length;
      for (let i = nextKeyPath; i < nextKeyPath + 4; i++) {
        // eslint-disable-next-line no-await-in-loop
        const ledgerPubKey = await this.walletStore.options.casperApp.getAddressAndPubKey(`m/44'/506'/0'/0/${i}`);
        const key = `02${ledgerPubKey.publicKey.toString('hex')}`;
        // eslint-disable-next-line no-await-in-loop
        const balance = this.motesToCspr(await fetchBalance(key));
        if (Big(balance).gt(0)) {
          this.ledgerKeys.funds.push({ key, balance, keyPath: i });
        } else {
          this.ledgerKeys.noFunds.push({ key, balance, keyPath: i });
        }
      }
    },
    setKey(key) {
      const options = this.walletStore.options;
      options.keyPath = key.keyPath;
      this.walletStore.connect(key.key.key, this.walletStore.wallet, options);
    },
    motesToCspr(amount) {
      return Big(amount || 0)
        .div(1000000000)
        .toString();
    },
    async connectTorus() {
      this.startLoading();
      try {
        const torusInstance = new Torus();
        await torusInstance.init({
          showTorusButton: false,
          network: getTorusNetwork(this.networkStore.network.chainId),
        });
        const publicKey = (await torusInstance?.login())?.[0];
        this.walletStore.connect(publicKey, 'torus', { torusInstance: torusInstance });
      } catch (e) {
        console.log(e);
        this.timeout = true;
        this.loading = false;
      }
    },
  },
};
</script>
