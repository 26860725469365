<template>
  <div>
    <WalletItem
      v-for="wallet in wallets.filter((w) => !w.hidden)"
      :title="wallet.title"
      :disabled="wallet.disabled"
      :logo="wallet.logo"
      :description="wallet.description"
      :download="wallet.download"
      @click="!wallet.disabled() && $emit('wallet', wallet.id)"
    />
  </div>
</template>

<script>
import WalletItem from '@/components/connect/WalletItem.vue';
import wallets from '@/helpers/wallets.js';
import { mdiInformation } from '@mdi/js';

export default {
  name: 'WalletList',
  components: { WalletItem },
  data: () => ({
    mdiInformation,
    wallets,
  }),
};
</script>
