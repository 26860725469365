import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/HomeView'),
  },
  {
    path: '/blocks',
    name: 'Blocks',
    component: () => import('@/views/BlocksView'),
  },
  {
    path: '/blocks/:hash',
    name: 'Block Info',
    component: () => import('@/views/BlockDetailsView'),
  },
  {
    path: '/deploys/:hash',
    name: 'Deploy Info',
    component: () => import('@/views/DeployDetailsView'),
  },
  {
    path: '/contracts/:hash',
    name: 'Contract Info',
    component: () => import('@/views/ContractDetailsView'),
  },
  {
    path: '/validators/:hash',
    name: 'Validator Info',
    component: () => import('@/views/ValidatorDetailsView'),
  },
  {
    path: '/accounts/:hash',
    name: 'Account Info',
    component: () => import('@/views/AccountDetailsView'),
  },
  {
    path: '/deploys',
    name: 'Deploys',
    component: () => import('@/views/DeploysView'),
  },
  {
    path: '/validators',
    name: 'Validators',
    component: () => import('@/views/ValidatorsView'),
  },
  {
    path: '/stats',
    name: 'Stats',
    component: () => import('@/views/StatsView'),
  },
  {
    path: '/contracts',
    name: 'Contracts',
    component: () => import('@/views/ContractsView'),
  },
];

// 3. Create the router instance and pass the `routes` option
// You can pass in additional options here, but let's
// keep it simple for now.
const router = createRouter({
  history: createWebHistory(),
  base: import.meta.env.BASE_URL,
  routes,
});

export default router;
