<template>
  <div
    class="pa-4 mx-auto d-flex justify-space-between align-content-center"
  >
    <router-link
      to="/"
      class="text-decoration-none"
    >
      <v-app-bar-title
        style="color: rgb(var(--v-theme-on-surface))"
      >
        <div class="d-inline-flex align-center">
          <v-img
            class="mr-4"
            width="75px"
          >
            <Logo />
          </v-img>
          <div>
            <div class="text-h5 font-weight-bold">
              Div<span
              class="d-inline-flex flex-wrap flex-shrink-1 mr-1"
              id="reverse"
            >3</span>
              <v-chip density="compact">
                <v-icon
                  start
                  size="x-small"
                  :icon="mdiInformationOutline"
                />
                {{ network }}
              </v-chip>
            </div>
            <div class="text-subtitle-2">Casper Network Explorer</div>
          </div>
        </div>
      </v-app-bar-title>
    </router-link>
    <div class="d-flex align-center">
      <template v-if="!$vuetify.display.smAndDown">
        <TabsMenu class="pr-5" />
        <v-hover
          v-slot="{ isHovering, props }"
          open-delay="200"
        >
          <v-btn
            :title="`Switch to ${ isDarkTheme ? 'light' : 'dark' } theme`"
            v-bind="props"
            @focus="isHovering = true"
            @blur="isHovering = false"
            size="x-small"
            class="mr-2 mt-1"
            height="32px"
            min-width="32px"
            rounded
            @click="toggleTheme"
          >
            <v-icon
              size="15"
              :icon="isDarkTheme ? mdiWhiteBalanceSunny : mdiWeatherNight"
            />
            <v-expand-x-transition>
              <span
                v-show="isHovering"
                class="ml-2"
              >
                Switch to {{ isDarkTheme ? 'light' : 'dark' }} theme
              </span>
            </v-expand-x-transition>
          </v-btn>
        </v-hover>
        <v-hover
          v-slot="{ isHovering, props }"
          open-delay="200"
        >
          <v-btn
            :title="`Switch to ${ network === 'Testnet' ? 'Mainnet' : 'Testnet' }`"
            v-bind="props"
            size="x-small"
            class="mr-2 mt-1"
            height="32px"
            min-width="32px"
            rounded
            @click="toggleNetwork"
          >
            <v-icon
              size="15"
              :icon="mdiServer"
            />
            <v-expand-x-transition>
              <span
                v-if="isHovering"
                class="ml-2"
              >Switch to {{ network === 'Testnet' ? 'Mainnet' : 'Testnet' }}</span>
            </v-expand-x-transition>
          </v-btn>
        </v-hover>
        <v-hover
          v-slot="{ isHovering, props }"
          open-delay="200"
        >
          <v-btn
            title="Connect"
            v-bind="props"
            size="x-small"
            class="mr-2 mt-1"
            height="32px"
            min-width="32px"
            @click="openConnectDialog"
            rounded
          >
            <v-avatar
              v-if="connected"
              size="15"
              :image="identIcon"
            />
            <v-avatar
              v-if="!connected"
              size="15"
              :icon="mdiAccount"
            />
            <v-expand-x-transition>
              <span
                v-if="isHovering && !connected"
                class="ml-2"
              >
                Connect
              </span>
              <span
                v-if="isHovering && connected"
                class="ml-2"
              >
                View account
              </span>
            </v-expand-x-transition>
          </v-btn>
        </v-hover>
      </template>
      <template v-else>
        <v-app-bar-nav-icon
          title="Menu"
          variant="text"
          @click.stop="toggleDrawer"
        ></v-app-bar-nav-icon>
      </template>
    </div>
  </div>
</template>

<script>
import TabsMenu from '@/components/TabsMenu.vue';
import generateAvatar from '@/helpers/identicon.js';
import Logo from '@/Logo.vue';
import { useConnectDialogStore } from '@/store/connectDialog.js';
import { useDrawerStore } from '@/store/drawer.js';
import { useNetworkStore } from '@/store/network.js';
import { useWalletStore } from '@/store/wallet.js';
import {
  mdiAccount,
  mdiEarth,
  mdiHome,
  mdiInformationOutline,
  mdiServer,
  mdiWeatherNight,
  mdiWhiteBalanceSunny,
} from '@mdi/js';
import { mapStores } from 'pinia';

export default {
  name: 'Header',
  components: { Logo, TabsMenu },
  mounted() {
    if (localStorage.theme) {
      this.$vuetify.theme.global.name = localStorage.theme;
    }
    this.networkStore.$subscribe(() => {
      this.network = localStorage.network;
    });
  },
  data: () => ({
    mdiInformationOutline,
    mdiWhiteBalanceSunny,
    mdiWeatherNight,
    mdiServer,
    mdiAccount,
    mdiHome,
    mdiEarth,
    network: localStorage.network === 'Testnet' ? 'Testnet' : 'Mainnet',
  }),
  watch: {
    network(newNetwork) {
      localStorage.network = newNetwork;
      this.networkStore.update();
    },
  },
  computed: {
    isDarkTheme() {
      return this.$vuetify.theme.global.current.dark;
    },
    identIcon() {
      return generateAvatar(this.walletStore.publicKey);
    },
    connected() {
      return this.walletStore.publicKey;
    },
    ...mapStores(useNetworkStore, useDrawerStore, useConnectDialogStore, useWalletStore),
  },
  methods: {
    openConnectDialog() {
      this.connectDialogStore.dialog = true;
    },
    toggleTheme() {
      this.$vuetify.theme.global.name = this.isDarkTheme ? 'light' : 'dark';
      localStorage.theme = this.$vuetify.theme.global.name;
    },
    toggleNetwork() {
      this.network = this.network === 'Testnet' ? 'Mainnet' : 'Testnet';
    },
    toggleDrawer() {
      this.drawerStore.drawer = !this.drawerStore.drawer;
    },
  },
};
</script>

<style lang="scss">
  #reverse {
    transition: .8s transform;
    transform: rotateY(360deg);
  }

  #reverse:hover {
    -webkit-transform: rotatey(180deg) !important;
    transform: rotateY(180deg) !important;
  }

  .toggleNetwork .v-input__details {
    padding: 0 !important;
    min-height: auto !important;
  }

  .toggleNetwork .v-input__control .v-selection-control {
    justify-content: end;
  }

  .toggleNetwork .v-selection-control--density-default {
    --v-input-control-height: auto;
  }
</style>
