import { createPinia } from 'pinia';
import { createApp } from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import { loadFonts } from './plugins/webfontloader';
import Router from './router/index.js';

const pinia = createPinia();

loadFonts();

createApp(App)
  .use(pinia)
  .use(vuetify)
  .use(Router)
  .mount('#app');
