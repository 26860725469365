import { getAccountHash } from '@/helpers/getAccountHash.js';
import { fetchUrefBalance, getMainPurse, getStateRootHash } from '@/helpers/rpc.js';
import { useNetworkStore } from '@/store/network.js';

async function fetchBalance(hash) {
  const networkStore = useNetworkStore();
  if (hash.includes('uref-')) {
    return hash;
  }
  const accountHash = getAccountHash(hash);
  const retrievedMainPurse = (await (await fetch(`${networkStore.network.dataApi}/accounts?account_hash=eq.${accountHash.replaceAll('account-hash-', '')}`)).json())[0]?.main_purse;
  const stateRootHash = await getStateRootHash();
  if (retrievedMainPurse) {
    return await fetchUrefBalance(retrievedMainPurse, stateRootHash);
  }
  const mainPurse = await getMainPurse(accountHash, stateRootHash);
  return await fetchUrefBalance(mainPurse, stateRootHash);
}

export { fetchBalance };
