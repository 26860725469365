<template>
  <v-card>
    <v-card-title>
      <v-avatar>
        <v-icon :icon="mdiAccount" />
      </v-avatar>
      Profile
    </v-card-title>
    <v-card-text class="text-center">
      <qrcode-vue
        style="border: 10px solid white; width: 320px; height: 320px; margin: auto"
        :value="publicKey"
        :size="300"
        render-as="svg"
        level="H"
      />
      <p class="text-h6">
        Your public key
      </p>
      <v-chip style="font-size: 0.82em;">
        {{ publicKey }}
      </v-chip>
      <v-chip class="mt-2">
        Connected with : {{ wallet }}
      </v-chip>
      <br/>
      <v-btn rounded class="mt-2" variant="tonal" @click="visitProfile" >
        View full profile
        <v-icon :icon="mdiChevronRight" end/>
      </v-btn>
    </v-card-text>
    <v-card-actions>
      <v-btn rounded @click="logout">
        Logout
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import wallets from '@/helpers/wallets.js';
import { useConnectDialogStore } from '@/store/connectDialog.js';
import { useWalletStore } from '@/store/wallet.js';
import { mdiAccount, mdiCheckCircle, mdiChevronRight } from '@mdi/js';
import { mapStores } from 'pinia';
import QrcodeVue from 'qrcode.vue';

export default {
  name: 'ConnectedProfile.vue',
  components: {
    QrcodeVue,
  },
  data: () => ({
    mdiAccount,
    mdiChevronRight,
    mdiCheckCircle,
  }),
  computed: {
    ...mapStores(useWalletStore, useConnectDialogStore),
    publicKey() {
      return this.walletStore.publicKey;
    },
    wallet() {
      return wallets.filter((w) => w.id === this.walletStore.wallet)[0].title;
    },
  },
  methods: {
    visitProfile() {
      this.$router.push('/accounts/'+this.publicKey);
      this.connectDialogStore.dialog = false;
    },
    async logout() {
      await this.walletStore.disconnect();
    }
  }
};
</script>
