<template>
  <v-app>
    <navigation-drawer />
    <Header
      class="container__small"
      style="width: 100%!important;"
    />
    <v-main>
      <v-container class="container__small">
        <ConnectDialog/>
        <GlobalSearch class="mb-5" />
        <router-view v-slot="{ Component }">
          <v-fade-transition leave-absolute>
            <component :is="Component" />
          </v-fade-transition>
        </router-view>
      </v-container>
    </v-main>

    <v-footer
      app
      inset
      absolute
    >
      <div class="mx-auto">
        <p class="mb-0 text-center">
          <router-link
            class="text-decoration-none"
            style="color: rgb(var(--v-theme-on-surface))"
            to="/"
          >
            <strong>
              Div3
            </strong>
          </router-link>
          by the creators of
          <a
            class="text-decoration-none"
            target="_blank"
            href="https://casperholders.io"
            style="color: rgb(var(--v-theme-on-surface))"
          >
            <strong>
              CasperHolders
              <v-icon
                title="Open in new"
                role="img"
                aria-hidden="false"
                size="x-small"
                :icon="mdiOpenInNew"
              />
              &#128150;
            </strong>
          </a>
        </p>
        <p class="mb-0 text-center text-caption">
          Hosted by OVH - 2 rue Kellermann - 59100 Roubaix - France - 1077 (+33 9
          72 10 10 07) - Powered by CoinGecko
        </p>
      </div>
    </v-footer>
  </v-app>
</template>

<script>
import ConnectDialog from '@/components/connect/ConnectDialog.vue';
import GlobalSearch from '@/components/GlobalSearch.vue';
import NavigationDrawer from '@/components/NavigationDrawer.vue';
import TabsMenu from '@/components/TabsMenu.vue';
import { useConnectDialogStore } from '@/store/connectDialog.js';
import { useWalletStore } from '@/store/wallet.js';
import { mdiOpenInNew } from '@mdi/js';
import { mapStores } from 'pinia';
import Header from './components/Header.vue';

export default {
  name: 'App',
  components: {
    ConnectDialog,
    TabsMenu,
    NavigationDrawer,
    GlobalSearch,
    Header,
  },
  watch: {
    '$route': {
      handler: (to, from) => {
        document.title = 'Div3' + (to.name ? ' - ' + to.name : '') + (to.params.hash ? ' ' + to.params.hash : '') || 'Div3';
      },
      immediate: true,
    },
  },
  computed: {
    ...mapStores(useWalletStore),
  },
  mounted() {
    try {
      if (!window.CasperWalletInstance) {
        const CasperWalletInstance = window.CasperWalletProvider();
        if (CasperWalletInstance) {
          window.CasperWalletInstance = CasperWalletInstance;
        }
      }
    } catch {
      //ignore error
    }

    this.$nextTick(() => {
      if (window.CasperWalletEventTypes != null) {
        Object.values(window.CasperWalletEventTypes).forEach((e) => window.addEventListener(e, (msg) => {
            const message = JSON.parse(msg.detail);
            if (message.isConnected) {
              this.walletStore.connect(message.activeKey, 'casperwallet');
            }
        }));
      }
      window.addEventListener('signer:initialState', (msg) => msg.detail.isConnected && this.walletStore.connect(msg.detail.activeKey, 'caspersigner'));
      window.addEventListener('signer:connected', (msg) => msg.detail.isConnected && this.walletStore.connect(msg.detail.activeKey, 'caspersigner'));
      window.addEventListener('signer:disconnected', (msg) => msg.detail.isConnected && this.walletStore.connect(msg.detail.activeKey, 'caspersigner'));
      window.addEventListener('signer:tabUpdated', (msg) => msg.detail.isConnected && this.walletStore.connect(msg.detail.activeKey, 'caspersigner'));
      window.addEventListener('signer:activeKeyChanged', (msg) => msg.detail.isConnected && this.walletStore.connect(msg.detail.activeKey, 'caspersigner'));
      window.addEventListener('signer:locked', (msg) => msg.detail.isConnected && this.walletStore.connect(msg.detail.activeKey, 'caspersigner'));
      window.addEventListener('signer:unlocked', (msg) => msg.detail.isConnected && this.walletStore.connect(msg.detail.activeKey, 'caspersigner'));
    });
  },
  data: () => ({
    mdiOpenInNew,
  }),
};
</script>

<style>
    .v-tooltip .v-overlay__content {
        background-color: rgb(var(--v-theme-surface)) !important;
        color: rgb(var(--v-theme-on-surface)) !important;
        border: 1px rgb(var(--v-theme-on-surface), 0.2) solid;
    }

    .container__small {
        max-width: 1400px !important;
    }

    .v-list-item-subtitle, .v-card-subtitle {
        opacity: 1 !important;
    }

    .ellipsis {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
</style>
