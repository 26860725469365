const wallets = [
  {
    id: 'metamask',
    title: 'Metamask',
    description: 'The leading self-custodial wallet',
    logo: '/img/metaMask.svg',
    disabled: () => !window.ethereum,
    download: 'https://metamask.io/'
  },
  {
    id: 'casperwallet',
    title: 'Casper Wallet',
    description: 'Native wallet for the Casper Network',
    logo: '/img/casperWallet.svg',
    disabled: () => !window.CasperWalletInstance,
    hidden: false,
    download: 'https://www.casperwallet.io/'
  },
  {
    id: 'caspersigner',
    title: 'Casper Signer',
    description: 'Legacy wallet for the Casper Network',
    logo: '/img/casper_logo.svg',
    disabled: () => !window.casperlabsHelper,
    hidden: false,
    download: 'https://chrome.google.com/webstore/detail/casper-signer/djhndpllfiibmcdbnmaaahkhchcoijce'
  },
  {
    id: 'ledger',
    title: 'Ledger',
    description: 'Unlock your ledger and open the Casper app first.',
    logo: '/img/ledger_logo.png',
    disabled: () => false,
  },
  {
    id: 'torus',
    title: 'Torus',
    description: 'Non-custodial Key Management, Meets Passwordless Auth.',
    logo: '/img/torus.svg',
    disabled: () => false,
  }
];

export default wallets;
