import { concat } from '@ethersproject/bytes';
import blake from 'blakejs';
import { Buffer } from 'buffer/';

function decodeBase16(base16String) {
  return new Uint8Array(Buffer.from(base16String, 'hex'));
}

function toAccountHash(publicKey) {
  const publicKeyHexBytes = decodeBase16(publicKey);
  const data = publicKeyHexBytes.subarray(1);
  const tag = publicKeyHexBytes[0];
  const separator = Uint8Array.from([0]);
  let stringTag;
  if (tag.toString() === '1') {
    stringTag = 'ed25519';
  } else {
    stringTag = 'secp256k1';
  }
  const prefix = Buffer.concat([
    Buffer.from(stringTag),
    separator,
  ]);
  let bytes;
  if (data.length === 0) {
    bytes = Uint8Array.from([]);
  } else {
    bytes = blake.blake2b(concat([prefix, data]), null, 32);
  }
  return Buffer.from(bytes);
}

function toAccountHashStr(publicKey) {
  return `account-hash-${toAccountHash(publicKey).toString('hex')}`;
}

function getAccountHash(hash) {
  if (hash.length === 64) {
    return hash;
  }

  return toAccountHashStr(hash);
}

export { getAccountHash, toAccountHash };
