<template>
  <v-navigation-drawer
    v-if="$vuetify.display.smAndDown"
    v-model="drawer"
    location="right"
    app
  >
    <v-list
      :lines="false"
      density="compact"
      nav
    >
      <v-list-item
        v-for="(item, i) in items"
        :key="i"
        :value="item"
        :to="item.to"
      >
        <template v-slot:prepend>
          <v-icon :icon="item.icon" />
        </template>

        <v-list-item-title v-text="item.title"></v-list-item-title>
      </v-list-item>
      <v-list-item
        @click="toggleNetwork"
      >
        <template v-slot:prepend>
          <v-icon :icon="mdiEarth" />
        </template>

        <v-list-item-title>Switch to {{ network === 'Testnet' ? 'Mainnet' : 'Testnet' }}</v-list-item-title>
      </v-list-item>
      <v-list-item
        @click="toggleTheme"
      >
        <template v-slot:prepend>
          <v-icon :icon="isDarkTheme ? mdiWhiteBalanceSunny : mdiWeatherNight" />
        </template>

        <v-list-item-title>Switch to {{ isDarkTheme ? 'light' : 'dark' }} theme</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { useDrawerStore } from '@/store/drawer.js';
import { useNetworkStore } from '@/store/network.js';
import {
  mdiAccount,
  mdiAccountSupervisorCircle,
  mdiEarth,
  mdiFile,
  mdiFileDocumentEdit,
  mdiGraphql,
  mdiHome,
  mdiPackageVariantClosed,
  mdiWeatherNight,
  mdiWhiteBalanceSunny,
} from '@mdi/js';
import { mapStores } from 'pinia';

export default {
  name: 'NavigationDrawer',
  data: () => ({
    mdiWhiteBalanceSunny,
    mdiWeatherNight,
    mdiAccount,
    mdiHome,
    mdiEarth,
    items: [
      {
        title: 'Home',
        icon: mdiHome,
        to: '/',
      },
      {
        title: 'Blocks',
        icon: mdiPackageVariantClosed,
        to: '/blocks',
      },
      {
        title: 'Deploys',
        icon: mdiFile,
        to: '/deploys',
      },
      {
        title: 'Validators',
        icon: mdiAccountSupervisorCircle,
        to: '/validators',
      },
      {
        title: 'Contracts',
        icon: mdiFileDocumentEdit,
        to: '/contracts',
      },
      {
        title: 'Stats',
        icon: mdiGraphql,
        to: '/stats',
      },
    ],
    drawer: false,
    network: localStorage.network === 'Testnet' ? 'Testnet' : 'Mainnet',
  }),
  mounted() {
    this.drawer = this.drawerStore.drawer;
    this.drawerStore.$subscribe((mutation, state) => {
      this.drawer = state.drawer;
    })
  },
  watch: {
    network(newNetwork) {
      localStorage.network = newNetwork;
      this.networkStore.update();
    },
    drawer(newDrawer) {
      this.drawerStore.drawer = newDrawer;
    }
  },
  computed: {
    isDarkTheme() {
      return this.$vuetify.theme.global.current.dark;
    },
    ...mapStores(useNetworkStore, useDrawerStore),
  },
  methods: {
    toggleTheme() {
      this.$vuetify.theme.global.name = this.isDarkTheme ? 'light' : 'dark';
      localStorage.theme = this.$vuetify.theme.global.name;
    },
    toggleNetwork() {
      this.network = this.network === 'Testnet' ? 'Mainnet' : 'Testnet';
    },
  }
};
</script>
