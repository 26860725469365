<template>
  <v-autocomplete
    title=""
    v-model="model"
    v-model:search="search"
    :items="items"
    :active="active"
    filled
    label="Search for a block / deploy / account / contract hash"
    item-title="name"
    item-value="name"
    placeholder="Start typing to "
    :menu-props="{closeOnContentClick:false, maxHeight: '500', offset: 20}"
    no-filter
    variant="solo"
    menu-icon=""
    hide-details
    hide-no-data
  >

    <template v-slot:item="{ props, item }">
      <div v-if="item.raw.header" class="d-flex ma-3 align-center">
        <v-chip>{{ item.raw.header }}</v-chip>
        <v-progress-linear v-if="item.raw.progress" indeterminate location="left"/>
        <v-divider v-else/>
      </div>
      <v-divider v-else-if="item.raw.divider" />
      <v-list-item
        v-else
        :title="item.raw.name"
        :to="item.raw.link"
        @click="search = ''; $router.push(item.raw.link)"
      >
        <v-list-item-subtitle class="mt-1 d-flex" style="grid-gap: 8px">
          <v-chip
            v-if="!Array.isArray(item.raw.group)"
            size="small"
            style="border-radius: 5px"
            label
          >
            {{ item.raw.group }}
          </v-chip>
          <template v-else>
            <v-chip
              v-for="group in item.raw.group"
              size="small"
              style="border-radius: 5px"
              label
            >
              {{ group }}
            </v-chip>
          </template>
        </v-list-item-subtitle>
        <template v-slot:prepend>
          <v-avatar color="background">
            <v-img
              v-if="item.raw.avatar"
              alt="Validator Logo"
              :src="item.raw.avatar"
            ></v-img>
            <v-icon
              v-if="item.raw.icon"
              :color="item.raw.color ? item.raw.color : 'on-surface'"
              :icon="item.raw.icon"
            />
          </v-avatar>
        </template>
      </v-list-item>
    </template>
  </v-autocomplete>
</template>

<script>
import { useNetworkStore } from '@/store/network.js';
import {
  mdiAccount,
  mdiAccountSupervisorCircle,
  mdiAlertCircle,
  mdiCheckboxMarkedCircle,
  mdiFileDocumentEdit,
  mdiPackageVariantClosed,
} from '@mdi/js';
import debounce from 'lodash.debounce';
import { mapStores } from 'pinia';

export default {
  name: 'GlobalSearch',
  computed: {
    items() {
      if (this.search === '') {
        return [

        ];
      }
      const blocks = this.blocks.length === 0 ? [] : [{ header: 'Blocks', progress: this.loadingBlocks }, ...this.blocks];
      const deploys = this.deploys.length === 0 ? [] : [{ header: 'Deploys', progress: this.loadingDeploys }, ...this.deploys];
      const validators = this.validators.length === 0 ? [] : [{ header: 'Validators', progress: this.loadingValidators }, ...this.validators];
      const contracts = this.contracts.length === 0 ? [] : [{ header: 'Contracts', progress: this.loadingContracts }, ...this.contracts];
      const accounts = this.accounts.length === 0 ? [] : [{ header: 'Accounts', progress: this.loadingAccounts }, ...this.accounts];
      const result = [
        ...blocks,
        ...deploys,
        ...validators,
        ...contracts,
        ...accounts,
      ];
      return result.length === 0 ?
        [{header: 'Nothing found...'}] : [
          ...blocks,
          ...deploys,
          ...validators,
          ...contracts,
          ...accounts,
        ];
    },
    ...mapStores(useNetworkStore),
  },
  created() {
    this.debounceSearchFetch = debounce(this.searchFetch, 250);
  },
  data() {
    return {
      mdiPackageVariantClosed,
      mdiCheckboxMarkedCircle,
      mdiAlertCircle,
      mdiFileDocumentEdit,
      mdiAccount,
      mdiAccountSupervisorCircle,
      active: undefined,
      loadingBlocks: false,
      loadingDeploys: false,
      loadingValidators: false,
      loadingContracts: false,
      loadingAccounts: false,
      model: null,
      search: null,
      blocks: [],
      deploys: [],
      validators: [],
      contracts: [],
      accounts: [],
      validatorsData: [],
    };
  },
  watch: {
    search() {
      this.debounceSearchFetch();
    },
  },
  mounted() {
    this.networkStore.$subscribe(async (mutation, state) => {
      this.validatorsData = (await (await fetch(`${this.networkStore.network.api}/validators/accountinfos`)).json());
    });
  },
  methods: {
    async searchFetch() {
      this.loadingBlocks = true;
      this.loadingDeploys = true;
      this.loadingValidators = true;
      this.loadingContracts = true;
      this.loadingAccounts = true;
      fetch(`${this.networkStore.network.dataApi}/blocks?hash=ilike.*${this.search}*&order=timestamp.desc&limit=3`).then((r) => {
        r.json().then((data) => {
          this.blocks = data.map((b) => {
            return {
              name: b.hash,
              group: this.localeTimestamp(b.timestamp),
              icon: mdiPackageVariantClosed,
              link: `/blocks/${b.hash}`,
            };
          });
          this.loadingBlocks = false;
        });
      });
      fetch(`${this.networkStore.network.dataApi}/deploys?hash=ilike.*${this.search}*&order=timestamp.desc&limit=3`).then((r) => {
        r.json().then((data) => {
          this.deploys = data.map((b) => {
            return {
              name: b.hash,
              group: this.localeTimestamp(b.timestamp),
              icon: b.result ? mdiCheckboxMarkedCircle : mdiAlertCircle,
              color: b.result ? 'success' : 'error',
              link: `/deploys/${b.hash}`,
            };
          });
          this.loadingDeploys = false;
        });
      });
      fetch(`${this.networkStore.network.dataApi}/contracts_list?or=(hash.ilike.*${this.search}*,package.ilike.*${this.search}*)&order=timestamp.desc&limit=3`).then((r) => {
        r.json().then((data) => {
          this.contracts = data.map((b) => {
            return {
              name: b.hash,
              group: [this.localeTimestamp(b.timestamp), b.package],
              icon: mdiFileDocumentEdit,
              link: `/contracts/${b.hash}`,
            };
          });

          this.loadingContracts = false;
        });
      });
      fetch(`${this.networkStore.network.dataApi}/accounts?public_key=ilike.*${this.search}*&order=public_key.desc&limit=3`).then((r) => {
        r.json().then((data) => {
          this.accounts = data.map((b) => {
            return {
              name: b.public_key,
              group: 'Account',
              icon: mdiAccount,
              link: `/accounts/${b.public_key}`,
            };
          });
          this.loadingAccounts = false;
        });
      });
      if (this.validatorsData.length === 0) {
        this.validatorsData = (await (await fetch(`${this.networkStore.network.api}/validators/accountinfos`)).json());
      }
      this.validators = this.validatorsData.filter((v) => {
        return (
          v.name.toLocaleLowerCase().indexOf(this.search.toLocaleLowerCase()) > -1
          || v.publicKey.toLocaleLowerCase().indexOf(this.search.toLocaleLowerCase()) > -1);
      }).slice(0, 3).map((v) => {
        return {
          name: v.name,
          group: v.name === v.publicKey ? 'Validator' : v.publicKey,
          avatar: v.logo,
          icon: mdiAccountSupervisorCircle,
          link: `/validators/${v.publicKey}`,
        };
      });
      this.loadingValidators = false;
    },
    localeTimestamp(value) {
      const date = new Date(value);
      return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
    },
  },
};
</script>
