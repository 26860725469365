<template>
  <div
    v-if="!$vuetify.display.xs"
    class="card-horizontal-list d-flex overflow-x-auto"
  >
    <v-tabs
      v-model="tab"
      :centered="true"
      :grow="true"
      :optional="true"
    >
      <v-tab
        v-for="(t, i) in tabs"
        :key="i"
        :value="t.title"
        :to="t.to"
      >
        <v-icon :icon="t.icon" />
        {{ t.title }}
      </v-tab>
    </v-tabs>
  </div>
</template>

<script>
import {
  mdiAccountSupervisorCircle,
  mdiFile,
  mdiFileDocumentEdit,
  mdiGraphql,
  mdiPackageVariantClosed,
} from '@mdi/js';

const menu = [
  {
    title: 'Blocks',
    icon: mdiPackageVariantClosed,
    to: '/blocks',
  },
  {
    title: 'Deploys',
    icon: mdiFile,
    to: '/deploys',
  },
  {
    title: 'Validators',
    icon: mdiAccountSupervisorCircle,
    to: '/validators',
  },
  {
    title: 'Contracts',
    icon: mdiFileDocumentEdit,
    to: '/contracts',
  },
  {
    title: 'Stats',
    icon: mdiGraphql,
    to: '/stats',
  },
];

export default {
  name: 'TabsMenu',
  mounted() {
    this.tab = undefined;
  },
  data: () => ({
    tab: undefined,
    tabs: menu,
  }),

  watch: {
    $route(to, from) {
      !menu.map((m) => m.to).includes(to) ? this.tab = undefined : '';
    },
  },
};
</script>
