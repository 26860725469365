import { shapes } from '@dicebear/collection';
import { createAvatar } from '@dicebear/core';

export default function generateAvatar(seed) {
  const avatar = createAvatar(shapes, {
    seed: seed,
    size: 200,
    backgroundColor: ['dc7765','a8f4aa','6532f0','d8fcfa','9cb9e3','01126b','e579cf','fbe9ab','ef86c9','de7868'],
    backgroundType: ["gradientLinear","solid"],
    shape1Color: ['dc7765','a8f4aa','6532f0','d8fcfa','9cb9e3','01126b','e579cf','fbe9ab','ef86c9','de7868'],
    shape2Color: ['dc7765','a8f4aa','6532f0','d8fcfa','9cb9e3','01126b','e579cf','fbe9ab','ef86c9','de7868',],
    shape3Color: ['dc7765','a8f4aa','6532f0','d8fcfa','9cb9e3','01126b','e579cf','fbe9ab','ef86c9','de7868',]
  });
  return avatar.toDataUriSync();
}
