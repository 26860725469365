<template>
  <div>
    <v-card
      variant="tonal"
      link
      class="mb-4"
      style="opacity: 1!important;"
      @click="$emit('usb', true)"
    >
      <v-card-text
        class="d-flex align-center"
      >
        <img
          src="/img/ledger_logo.png"
          width="32"
          alt="Ledger Logo"
          class="mr-3"
        >
        <div>
          <span class="text-body-1">
            Ledger USB
          </span>
          <div>Support Nano S/X on Web & Android Chrome (OTG)</div>
        </div>
        <v-icon class="ml-auto" :icon="mdiChevronRight"/>
      </v-card-text>
    </v-card>
    <v-card
      variant="tonal"
      link
      class="mb-4"
      style="opacity: 1!important;"
      @click="$emit('usb', false)"
    >
      <v-card-text
        class="d-flex align-center"
      >
        <img
          src="/img/ledger_logo.png"
          width="32"
          alt="Ledger Logo"
          class="mr-3"
        >
        <div>
          <span class="text-body-1">
            Ledger Bluetooth
          </span>
          <div>Support Nano X on Web & Mobile</div>
        </div>
        <v-icon class="ml-auto" :icon="mdiChevronRight"/>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mdiChevronRight } from '@mdi/js';

export default {
  name: 'LedgerConnectionTypes',
  data: () => ({
    mdiChevronRight
  })
};
</script>
