<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 460 365"
  >
    <path
      class="cls-1 fill-background"
      d="M435.6,124.4A202.64,202.64,0,0,1,62,125.35C53.2,138.57,25,185.61,25,249c0,124.26,100.74,225,225,225S475,373.26,475,249C475,185.8,445.46,138.8,435.6,124.4Z"
      transform="translate(-20 -114)"
    />
    <template v-for="index in Math.floor(Math.random()*10+10)" :key="index">
      <circle
        class="bubble"
        :cx="Math.random()*320+60"
        :cy="Math.random()*140+160"
        :fill="randomColor(index)"
        :stroke="randomColor(index)"
        :r="randomRadius()"
      />
    </template>
  </svg>
</template>

<script>
  export default {
    name: 'Logo',
    methods: {
      randomRadius() {
        return Math.floor(Math.random() * 15 + 10);
      },
      randomColor(index) {
        const colors = [
          '#dc7765',
          '#a8f4aa',
          '#6532f0',
          '#d8fcfa',
          '#9cb9e3',
          '#01126b',
          '#e579cf',
          '#fbe9ab',
          '#ef86c9',
          '#de7868',
        ]
        return colors[index % 9];
      },
    },
  };
</script>


<style lang="scss">

  .fill-background {
    fill: rgb(var(--v-theme-surface));
    stroke: rgb(var(--v-theme-background));
    stroke-miterlimit: 5;
    stroke-width: 10px;
  }

  @keyframes float {
    0% {
      transform: translateY(-5+em);
      opacity: 0;
    }
    100% {
      transform: translateY(0em) translateX(0);
      opacity: 1;
    }
  }

  @keyframes fade-in {
    0% {
      opacity: 0
    }
    100% {
      opacity: 1
    }
  }

  .bubble {
    opacity: 0;
    stroke-miterlimit: 5;
    stroke-width: 10px;
    animation: fade-in 3s forwards, float 3s ease-in-out infinite alternate;
  }

  @for $i from 1 to 9 {
    .bubble:nth-child(#{$i}) {
      animation-delay: ($i* 0.5)+s;
    }
  }
</style>
