import { defineStore } from 'pinia';

const testnet = {
  dataApi: 'https://data.testnet.casperholders.com',
  api: 'https://api.testnet.casperholders.io',
  rpc: 'https://node.testnet.casperholders.com/rpc',
  supply: 'uref-5d7b1b23197cda53dec593caf30836a5740afa2279b356fae74bf1bdc2b2e725-007',
  chainId: 'casper-test',
};

const mainnet = {
  dataApi: 'https://data.casperholders.com',
  api: 'https://api.casperholders.io',
  rpc: 'https://node.casperholders.com/',
  supply: 'uref-8032100a1dcc56acf84d5fc9c968ce8caa5f2835ed665a2ae2186141e9946214-007',
  chainId: 'casper',
};

export const useNetworkStore = defineStore('network', {
  state: () => ({
    network: localStorage.network === 'Testnet' ? testnet : mainnet,
  }),
  actions: {
    update() {
      this.network = localStorage.network === 'Testnet' ? testnet : mainnet;
    },
  },
});
